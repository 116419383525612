<template>
	<div class="bgc" v-if="test">
		<div class="w">
			<div class="bgc-kong"></div>
			<div class="exam">
				<div class="examCont">
					<div class="examTop1"></div>
					<div class="examTop flex3">
						<div class="goBack" @click="goback">
							<img src="../../assets/img/tiku/icon_fanhui.png" alt />
							返回
						</div>
						<div class="examName">{{exam.examsubject}}</div>
						<div class="step">
							<div>时间：{{exam.examtime}}分钟</div>
							<div>共{{exam.questionnum}}道试题</div>
							<!-- <div>总分：{{exam.total}}分</div>
							<div>及格：{{exam.passing}}分</div>-->
						</div>
						<div class="examTime">
							剩余时间：
							<div>{{ time.Hour }}</div>:
							<div>{{ time.Minute }}</div>:
							<div>{{ time.Second }}</div>
						</div>
					</div>
					<div class="examType">
						<!-- 试题列表 -->
						<div
						class="examList"
						:class="`examList${item.id}`"
						:ref="`examList${item.id}`"
						v-for="(item, i) in examList"
						:key="i"
						>
							<div class="topic flex">
								<div v-if="item.quetiontype == 4" class="status">单选题</div>
								<div v-if="item.quetiontype == 1" class="status status1">多选题</div>
								<div v-if="item.quetiontype == 5" class="status status2">判断题</div>
								<div v-if="item.quetiontype== 2" class="status">填空题</div>
								<div v-if="item.quetiontype == 3" class="status status1">简答题</div>
								<div v-if="item.quetiontype== 6" class="status status2">应用题</div>
								<div v-if="item.quetiontype== 7" class="status status2">作文</div>
								<div v-if="item.quetiontype== 8" class="status status2">辨析题</div>
								<div class="index">{{i+1}}.</div>
								<div class="text" v-html="item.subject"></div>
							</div>
							<div class="option" v-if="item.quetiontype!= 6">
								<!-- 单选题目 -->
								<el-radio-group
								v-model="item.radio"
								@change="change($event,item.id)"
								v-if="item.quetiontype== 4"
								>
									<div class="choose" v-for="(item1, index1) in item.option" :key="index1">
										<el-radio :label="item1.option">
											<span v-html="item1.content"></span>
										</el-radio>
									</div>
								</el-radio-group>
								<!-- 多选题目 -->
								<el-checkbox-group
								v-model="item.radio1"
								v-if="item.quetiontype== 1&&flag"
								@change="handleChecked($event,item.id)"
								>
									<el-checkbox
									class="choose"
									:label="item1.option"
									v-for="(item1, index1) in item.option"
									:key="index1"
									>
										<span v-html="item1.content"></span>
									</el-checkbox>
								</el-checkbox-group>
								<!-- 判断题 -->
								<el-radio-group
								v-model="item.radio2"
								@change="change1($event,item.id)"
								v-if="item.quetiontype == 5"
								>
									<div class="choose" v-for="(item, index) in item.option" :key="index">
										<el-radio :label="item">
											<span v-html="item"></span>
										</el-radio>
									</div>
								</el-radio-group>
								<!-- 填空题 -->
								<!-- <textarea class="response" v-if="item.quetiontype == 2 " placeholder="请输入内容" /> -->
								<el-input
								class="response"
								type="textarea"
								v-model="item.fill"
								@input="changeFill($event,item.id)"
								v-if="item.quetiontype == 2 || item.quetiontype == 3||item.quetiontype == 7 ||item.quetiontype == 8"
								:autosize="{ minRows: 2}"
								placeholder="请输入内容"
								></el-input>
							</div>
							<div v-else>
								<div v-if="item.son.length > 0" class="option option1" v-for="(item1, index1) in item.son" :key="index1">
									<div class="topic1 topic">
										<span class="index">({{index1+1}})</span>
										<span v-html="item1.subject"></span>
										<span class="warp" v-if="item1.quetiontype == 4">(单选)</span>
										<span class="warp" v-if="item1.quetiontype == 1">(多选)</span>
										<span class="warp" v-if="item1.quetiontype == 5">(判断)</span>
										<span class="warp" v-if="item1.quetiontype== 2">(填空)</span>
										<span class="warp" v-if="item1.quetiontype == 3">(简答)</span>
										<span class="warp" v-if="item1.quetiontype== 7">(作文)</span>
										<span class="warp" v-if="item1.quetiontype== 8">(辨析)</span>
									</div>
									<el-radio-group
									v-model="item1.fill"
									@change="change2($event,item1.id,item.quetiontype,item.son,item.id)"
									v-if="item1.quetiontype== 4"
									>
										<div class="choose" v-for="(item2, index2) in item1.option" :key="index2">
											<el-radio :label="item2.option">
												<span v-html="item2.content"></span>
											</el-radio>
										</div>
									</el-radio-group>
									<el-checkbox-group
									v-model="item1.fill"
									v-if="item1.quetiontype== 1&&flag"
									@change="handleChecked1($event,item1.id,item.quetiontype,item.son,item.id)"
									>
										<el-checkbox
										class="choose"
										:label="item2.option"
										v-for="(item2, index2) in item1.option"
										:key="index2"
										>
											<span v-html="item2.content"></span>
										</el-checkbox>
									</el-checkbox-group>
									<el-radio-group
									v-model="item1.fill"
									@change="change3($event,item1.id,item.quetiontype,item.son,item.id)"
									v-if="item1.quetiontype == 5"
									>
										<div class="choose" v-for="(item2, index2) in item1.option" :key="index2">
											<el-radio :label="item2">
												<span v-html="item2"></span>
											</el-radio>
										</div>
									</el-radio-group>
									<el-input
									class="response"
									type="textarea"
									v-model="item1.fill"
									@input="changeFill1($event,item1.id,item.quetiontype,item.son,item.id)"
									v-if="item1.quetiontype == 2 || item1.quetiontype == 3||item1.quetiontype == 7 ||item1.quetiontype == 8"
									:autosize="{ minRows: 2}"
									placeholder="请输入内容"
									></el-input>
								</div>
								<!-- 填空题 -->
								<!-- <textarea class="response" v-if="item.quetiontype == 2 " placeholder="请输入内容" /> -->
								<el-input
								class="response"
								type="textarea"
								v-model="item.fill"
								@input="changeFill($event,item.id)"
								v-else
								:autosize="{ minRows: 2}"
								placeholder="请输入内容"
								></el-input>
							</div>
						</div>
					</div>
				</div>
				<!-- 右侧答题卡 -->
				<div class="answer">
					<div class="answerTitile">答题卡</div>
					<div class="answerTop">
						<div class="whether">
							<div class="whetherItem">
								<div></div>
								<div>为已答</div>
							</div>
							<div class="whetherItem">
								<div></div>
								<div>为未答</div>
							</div>
						</div>
						<div class="num">
							你还有
							<span>{{num}}道题</span>未答
						</div>
					</div>
					<div class="answerCard">
						<div class="answerList flex">
							<div
							:class="{ noAnswer: item.status == 0 }"
							v-for="(item, index) in cardList"
							:key="index"
							@click="goDingyue(item.id)"
							>{{index+1}}</div>
						</div>
					</div>
					<div class="submit" @click="submit">交卷</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { examination, handPapers } from "../../assets/js/question.js";
	import { examination, handExam } from "../../assets/js/question.js";
	let timer = null;
	let singleAnswer = {};
	export default {
		name: "exam",
		data() {
			return {
				// 拿到数据之后 再进行数据展示
				test: false,
				flag: false,
				timeTotal: 0,
				radio1: "上海",
				dingYue: "",
				time: {
				Hour: 0,
				Minute: 0,
				Second: 0,
				},
				// 后台数据
				c_e_id: 0,
				exam: "",
				// 试题列表
				examList: [],
				// 答题卡数据
				cardList: [],
				num: 0,
				// 答案
				answer: [],
				valueData: "",
			};
		},
		mounted() {
			// console.log(this.$route.query.c_e_id);
			// return
			if (!this.$route.query.c_e_id) {
				this.$router.push("/index");
				return;
			}
			this.c_e_id = this.$route.query.c_e_id;
			this.user_id = this.$route.query.user_id;
			window.scroll(0, 0);
			this.examData();
			// 定时器
		},
		beforeDestroy() {
			//页面关闭时清除定时器
			clearInterval(timer);
		},
		computed: {},
		watch: {},
		methods: {
			// 试卷
			examData() {
				examination({ exam_id: this.c_e_id, user_id: this.user_id }).then(
					(res) => {
						// console.log(res.data.exam);
						if (res.code == 200) {
							this.exam = res.data.exam;
							res.data.exam.examquestions.forEach((item) => {
								this.$set(item, "radio", "");
								this.$set(item, "radio1", []);
								this.$set(item, "radio2", "");
							});
							res.data.exam.examquestions.forEach((item) => {
								this.$set(item, "status", "");
							});
							this.flag = true;
							// console.log(res.data.exam.examquestions);
							this.examList = res.data.exam.examquestions;
							this.cardList = res.data.exam.examquestions;
							this.num = res.data.exam.examquestions.length;
							this.timeTotal = (res.data.exam.examtime - 0) * 60;
							this.test = true;
							this.countdown();
						} else {
							this.$message.error(res.msg);
						}
					}
				);
			},
			// 返回上一页
			goback() {
				this.$router.go(-1);
			},
			// 单选
			change(e, id) {
				// console.log(e, id);
				this.changeCard(id, 0);
				// console.log(e, id);
				singleAnswer = { id: id, option: e };
				this.changeAnswer(singleAnswer, id);
			},
			// 应用题的单选
			change2(e, id, type, son, itemId) {
				// console.log("应用题的单选");
				// console.log(e, id, type, son, itemId);
				// let flag = false;
				let numAnswer = 0;
				son.forEach((item) => {
					if (item.fill) {
						numAnswer++;
					}
				});
				// console.log(numAnswer);
				// console.log(son.length);
				if (numAnswer == son.length) {
					this.changeCard(itemId, 0);
				} else {
					this.changeCard(itemId, 1);
				}
				singleAnswer = { id: id, option: e };
				this.changeAnswer(singleAnswer, id);
			},
			// 判断
			change1(e, id) {
				// console.log(e, id);
				// 改变答题卡状态
				this.changeCard(id, 0);
				// 答案数组里面 添加此项答案

				if (e == "对") {
					e = 1;
				} else {
					e = 0;
				}
				singleAnswer = { id: id, option: e };
				this.changeAnswer(singleAnswer, id);
			},
			// 应用题中的判断题
			change3(e, id, type, son, itemId) {
				if (e == "对") {
					e = 1;
				} else {
					e = 0;
				}
				let numAnswer = 0;
				son.forEach((item) => {
					if (item.fill) {
						numAnswer++;
					}
				});
				// console.log(numAnswer);
				// console.log(son.length);
				if (numAnswer == son.length) {
					this.changeCard(itemId, 0);
				} else {
					this.changeCard(itemId, 1);
				}
				singleAnswer = { id: id, option: e };
				this.changeAnswer(singleAnswer, id);
			},
			// 多选
			handleChecked(e, id) {
				// console.log(e, id, e.length);
				// 改变答题卡状态
				if (e.length > 0) {
					this.changeCard(id, 0);
				} else {
					this.changeCard(id, 1);
				}
				// ABC答案排序
				e.sort(function (a, b) {
					return (a + "").localeCompare(b + "");
				});
				// 答案数组里面 添加此项答案
				singleAnswer = { id: id, option: e };
				this.changeAnswer(singleAnswer, id);
			},
			// 应用题中的多选题
			handleChecked1(e, id, type, son, itemId) {
				// console.log(e, id, type, son, itemId);
				let numAnswer = 0;
				son.forEach((item) => {
					if (item.fill.length) {
						numAnswer++;
					}
				});
				if (e.length > 0) {
					if (numAnswer == son.length) {
						this.changeCard(itemId, 0);
					} else {
						this.changeCard(itemId, 1);
					}
				} else {
					this.changeCard(itemId, 1);
				}
				// ABC答案排序
				e.sort(function (a, b) {
					return (a + "").localeCompare(b + "");
				});
				// 答案数组里面 添加此项答案
				singleAnswer = { id: id, option: e };
				this.changeAnswer(singleAnswer, id);
			},
			// 填空题
			changeFill(e, id) {
				singleAnswer = { id: id, option: e };
				// console.log(singleAnswer.option);

				if (singleAnswer.option) {
					this.changeCard(id, 0);
				} else {
					this.changeCard(id, 1);
				}
				this.changeAnswer(singleAnswer, id);
			},
			// 应用题的填空题
			changeFill1(e, id, type, son, itemId) {
				// console.log(e, id, son);
				let numAnswer = 0;
				son.forEach((item) => {
					if (item.fill) {
						numAnswer++;
					}
				});
				if (numAnswer == son.length) {
					this.changeCard(itemId, 0);
				} else {
					this.changeCard(itemId, 1);
				}
				// console.log(numAnswer);
				singleAnswer = { id: id, option: e };
				this.changeAnswer(singleAnswer, id, type);
			},
			// 改变答题卡的状态
			//  changeCard cancel为0 改变为选中状态   为1 改变为未选中状态
			changeCard(id, cancel) {
				// console.log(cancel);
				this.cardList.forEach((item) => {
					if (item.id == id) {
						// console.log(item.id);
						if (cancel == 0) {
							// console.log("已做");
							if (item.status == 0) {
								this.num--;
							}
							item.status = 1;
						} else {
							// console.log("未做");
							if (item.status == 1) {
								this.num++;
							}

							item.status = 0;
						}
					}
				});
			},
			// 改变答案
			changeAnswer(singleAnswer1, id, type) {
				// replace 判断是否替换答案
				let replace = 0;
				this.answer.findIndex((item) => {
					if (item.id == id) {
						item.option = singleAnswer1.option;
						if (singleAnswer1.option) {
							if (type != 6) {
								this.changeCard(id, 0);
							}
							replace = 1;
						}
					}
				});
				if (replace == 0) {
					// console.log(singleAnswer1.option);
					// 如果清空了的输入框的内容 数组里面不添加内容
					if (singleAnswer1.option || singleAnswer1.option == 0) {
						this.answer.push(singleAnswer);
					}
				}
				// 打印答案数组
				// console.log(this.answer);
			},
			// 提交试卷
			submit() {
				let useTime = 0;
				// console.log(this.exam.examtime - 0);
				useTime = (this.exam.examtime - 0) * 60 - (this.timeTotal - 0);
				// console.log(useTime);

				handExam({
					exam_id: this.c_e_id,
					user_id: this.user_id,
					time_spent: useTime,
					myanswer: this.answer,
				}).then((res) => {
				// console.log(res);
					this.$router.replace({
						path: "/analysis",
						query: { id: res.data.id, user_id: this.user_id },
					});
				});
			},

			// 返回上一页
			back() {
				this.$router.go(-1);
			},
			// 锚点定位
			goDingyue(id) {
				// console.log(this.$refs[`examList${id}`][0].getBoundingClientRect());
				this.$el
				.querySelector(`.examList${id}`)
				.scrollIntoView({ block: "end", behavior: "smooth" });
			},
			countdown() {
				if (this.timeTotal <= 0) {
					return;
				} else {
					this.countDown();
					timer = setInterval(this.countDown, 1000);
				}
			},
			countDown() {
				if (this.timeTotal <= 0) {
					clearInterval(timer);
				}
				let timeTotal = this.timeTotal;
				let h = parseInt((timeTotal / 60 / 60) % 24);
				h = h < 10 ? "0" + h : h;
				let m = parseInt((timeTotal / 60) % 60);
				m = m < 10 ? "0" + m : m;
				let s = parseInt(timeTotal % 60);
				s = s < 10 ? "0" + s : s;
				this.time = {
					Hour: h,
					Minute: m,
					Second: s,
				};
				this.timeTotal = this.timeTotal - 0 - 1;
				// console.log(this.time.Second);
			},
		},
	};
</script>
<style lang="less" scoped>
	/deep/ .text img {

	}
	.bgc {
		margin: 0 auto;
		width: 100%;
		min-height: 100vh;
		// padding-top: 20px;
		box-sizing: border-box;
		background-color: #f5f5f5;
		// padding-bottom: 50px;
	}
	.w {
		margin: 0 auto;
		width: 1200px;
	}
	.bgc-kong {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 20px;
		background-color: #f5f5f5;
		z-index: 3;
	}
	.exam {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 1200px;
		margin: 0 auto;
	}
	.examCont {
		width: 933px;
		background-color: #fff;
		// padding-bottom: 50px;
		box-sizing: border-box;
		min-height: 100vh;
		.examTop1 {
			height: 196px;
			background-color: #fff;
			z-index: 3;
		}
		.examTop {
			position: fixed;
			top: 20px;
			width: 933px;
			height: 196px;
			color: #fff;
			background-image: url("../../assets/img/tiku/bg_1_15.png");
			background-repeat: no-repeat;
			z-index: 2;
			.goBack {
				position: absolute;
				top: 20px;
				left: 30px;
				cursor: pointer;
				font-size: 14px;
				display: flex;
				align-items: center;
				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
			.examName {
				padding-top: 40px;
				font-size: 30px;
			}
			.step {
				display: flex;
				margin: 19px 0;
				font-size: 16px;
				color: #fff;

				div {
					margin-right: 20px;
				}
			}
			.examTime {
				display: flex;
				align-items: center;
				font-size: 16px;
				div {
					width: 40px;
					height: 40px;
					margin: 0 10px;
					text-align: center;
					line-height: 40px;
					background-color: #5cbeff;
					border-radius: 5px;
				}
			}
		}
	}
	.examType {
		margin: 0 55px;
		margin-top: 40px;
		font-size: 18px;
		.examList {
			.topic {
				// display: flex;
				// align-items: center;
				line-height: 30px;

				.status {
					flex-shrink: 0;
					margin-right: 20px;
					width: 80px;
					height: 30px;
					line-height: 30px;
					color: #fff;
					text-align: center;
					border-radius: 15px;
					background-color: #029aff;
				}
				.status1 {
					background-color: #ff7402;
				}
				.status2 {
					background-color: #b502ff;
				}
			}
			.option {
				margin-top: 35px;
				margin-left: 100px;
				.choose {
					display: block;
					margin-bottom: 30px;
				}
			}
			.option1 {
				margin-top: 10px !important;
				margin-left: 145px;
			}
			.topic1 {
				flex: 0;
				// margin-left: 20px;
				margin-bottom: 20px;
				// div {
				//   flex-shrink: 0;
				// }
			}
			.text {
				text-align: justify;
			}
			.filling {
				width: 100%;
				text-indent: 20px;
				margin-bottom: 30px;
				height: 40px;
				background-color: #fff;
				border: 1px solid #ccc;
			}
			.response {
				margin-left: 40px;
				width: 600px;
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 30px;
				box-sizing: border-box;
				border-radius: 5px;
			}
		}
	}
	// 右侧答题卡
	.answer {
		position: fixed;
		top: 20px;
		// right: 0;
		right: calc((100vw - 1200px) / 2);
		width: 250px;
		min-height: 80vh;
		background-color: #ffffff;
		padding-bottom: 30px;
		.answerTitile {
			text-align: center;
			line-height: 40px;
			height: 40px;
			background-color: #029aff;
			color: #ffffff;
			font-size: 18px;
		}
		.answerTop {
			height: 60px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-bottom: 1px solid #eaeaea;
			text-align: center;
			.whether {
				display: flex;
				justify-content: center;
				.whetherItem {
					display: flex;
					align-items: center;
					div:first-child {
						width: 16px;
						height: 16px;
						border-radius: 50%;
						overflow: hidden;
						background-color: #029aff;
					}
					&:first-child {
						margin-right: 10px;
					}
					&:last-child {
						div:first-child {
							border: 1px solid #029aff;
							box-sizing: border-box;
							background-color: transparent;
						}
					}
				}
			}
			.num {
				margin-top: 5px;
				span {
					color: #ff0000;
				}
			}
		}
	}
	.answerCard {
		margin-top: 20px;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 60vh;
		.type {
			margin: 12px 0;
			padding-left: 10px;
			height: 24px;
			line-height: 24px;
			color: #029aff;
			font-size: 16px;
			border-left: 5px solid #029aff;
		}
		.answerList {
			margin: 0 30px;
			flex-wrap: wrap;
			width: 190px;
			// min-height: 60vh;
			// background-color: antiquewhite;
			div {
				cursor: pointer;
				width: 36px;
				height: 36px;
				color: #fff;
				text-align: center;
				line-height: 36px;
				font-size: 14px;
				background-color: #029aff;
				border-radius: 50%;
				margin-right: 41px;
				margin-bottom: 20px;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			.noAnswer {
				color: #029aff;
				border: 1px solid #029aff;
				box-sizing: border-box;
				background-color: transparent;
			}
		}
	}
	.submit {
		margin: 0 auto;
		margin-top: 30px;
		width: 140px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 18px;
		color: #ffffff;
		cursor: pointer;
		background-color: #029aff;
		border-radius: 10px;
	}
	// 答案解析
	.result {
		font-size: 18px;
		color: #343434;
		margin-bottom: 20px;
		span {
			color: #029aff;
			margin: 0 5px;
		}
		.big {
			font-size: 34px;
		}
	}
	.resultBtn {
		margin-bottom: 20px;
		width: 180px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		font-size: 18px;
		color: #fff;
		background: #029aff;
	}
	.resultBtn1 {
		color: #343434;
		background-color: #eaeaea;
	}
	.analysis {
		margin-bottom: 40px;
		font-size: 18px;
		span {
			color: #029aff;
		}
	}
	/deep/ .el-radio {
		color: #343434;
	}
	::v-deep .el-radio__label {
		width: 100% !important;
		text-overflow: ellipsis;
		white-space: normal;
		line-height: 18px;
		// word-wrap: break-word !important;    无效
	}
	::v-deep .el-checkbox__label {
		display: inline-grid;
		white-space: pre-line;
		word-wrap: break-word;
		overflow: hidden;
		line-height: 20px;
	}
	::v-deep .el-radio__input.is-disabled + span.el-radio__label {
		cursor: default;
		color: #333333;
	}
	/deep/ .el-radio__input.is-disabled .el-radio__inner {
		cursor: default !important;
	}

	.answerCard::-webkit-scrollbar {
		width: 4px;
		height: 5px;
	}
	.answerCard::-webkit-scrollbar-track {
		background: rgba(191, 191, 191, 0.4);
		border-radius: 4px;
	}
	.answerCard::-webkit-scrollbar-thumb {
		background: rgba(191, 191, 191, 1);
		border-radius: 4px;
	}
	.index {
		margin-right: 10px;
	}
	.warp {
		flex-shrink: 0;
	}
</style>